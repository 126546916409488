:root{
  --main-nav-expanded-size:240px;
  --main-nav-collapsed-size:56px;
  --sidebar-width:320px;
  --sidebar-header-height:24px;
  --icon-bg:24px;
  --radius:4px;
  --radius-large:8px;
  --radius-curve:8px;
  --text-padding:4px;
  --field-height:32px;
  --button-height:32px;
  --field-border-radius:18px;
  --table-header-height:48px;
  --header-height:48px;
  --backdrop-blur:10px;
  --indicator-border-size:4px;
  --dock-height:48px;
}

.border-radius-0{
  border-radius:0;
}

.border-radius{
  border-radius: var(--radius);
}
.border-radius-top{
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
}

.border-radius-large{
  border-radius: var(--radius-large);
}
.border-radius-curve{
  border-radius: 1000px;
}

.field-height{
  height:var(--field-height);
}

.font-sm{
  font-size:0.8rem;
}

.font-md{
  font-size:0.9rem;
}
.font-large{
  font-size:1.2rem;
}
.font-xlarge{
  font-size:1.4rem;
}
.font-bold{
  font-weight: bold;
}
.aspect-ratio-1{
  aspect-ratio:1;
}

//  switch (size) {
//    case 'xsmall':
//      return 0.7;
//    case 'small':
//      return 0.9;
//    case 'normal':
//      return 1;
//    case 'large':
//      return 1.1;
//    case 'xlarge':
//      return 1.2;
//    case 'xxlarge':
//      return 1.3;
//    case 'xxxlarge':
//      return 1.4;
//    default:
//      return 1;
//  }

.font-size-xsmall{
  font-size:0.8rem;
}
.font-size-small{
  font-size:0.9rem;
}
.font-size-normal{
  font-size:1rem;
}
.font-size-large{
  font-size:1.1rem;
}
.font-size-xlarge{
  font-size:1.2rem;
}
.font-size-xxlarge{
  font-size:1.3rem;
}
.font-size-xxxlarge{
  font-size:01.4rem;
}

.mail-container{
  max-width:600px;
  width:100%;
}
