.gantt {
  background-color: var(--body-bg)!important;
}
.gantt .gantt-container{
  background-color: var(--body-bg)!important;
}
.gantt .gantt-side .gantt-side-container{
  background-color: var(--body-bg)!important;
}

.gantt-table-body .gantt-table-item {
  background:var(--table-row-bg);
  color:var(--body-color);
  border-bottom:1px solid var(--table-cell-border-color);
}

.gantt .gantt-main-container{
  background-color: var(--body-bg)!important;
}

.gantt .gantt-main-container .gantt-item{
  border-bottom: 1px solid var(--table-cell-border-color);
  background:var(--table-row-bg);
  color:var(--body-color);
}
.gantt-header .gantt-container-header{
  background-color:var(--table-header-bg);
  color:var(--table-header-color);
}
.gantt-table-header{
  background:var(--table-header-bg);
  color:var(--table-header-color);
  border-right:1px solid var(--border-color);
  border-bottom:1px solid var(--border-color);
}
.gantt-calendar svg{
  background-color:var(--table-header-bg);
  color:var(--table-header-color);
}

.gantt-calendar-header{
  border-bottom:1px solid var(--border-color);
}

.gantt-calendar-header .primary-text{
  fill:var(--fade-text-color);
}
.gantt-calendar-header .secondary-text{
  fill:var(--body-color);
}

.gantt-calendar-grid .secondary-line{
  stroke:var(--table-cell-border-color);
}

.gantt-calendar .primary-line {
  stroke:var(--border-color);
}
.gantt-footer{
  background-color:transparent;
  border-top:none;
}

.gantt .gantt-side{
  border-right:1px solid var(--border-color);
}

.gantt-table-empty .gantt-table-body-container .empty-text{
  display:none;
}
