.rt-text,
.rt-email,
.rt-url,
.rt-phone
{
  vertical-align: middle;
  height: 100%;
  border: none;
  width: 100%;
}
