@import "src/assets/css/custom/color";
@import "src/assets/css/custom/size";

.modal-content {
  height: 100%;
  overflow:hidden;
}

.modal-md {
  max-width: 500px !important;
}

.modal-normal {
  max-width: 768px !important;
  width:768px;
}

.modal-small {
  //width:640px;
  //max-width: 640px !important;
  width:448px;
  max-width: 448px !important;
}

.modal-xsmall {
  width:448px;
  max-width: 448px !important;
}

.modal-large {
  max-width: 1024px !important;
  width:1024px;
}
.modal-fill {
  max-width: 100% !important;
  width:100%;
}

.width-small{
  width:448px;
}
.width-normal{
  width:768px;
}
.width-xsmall{
  width:448px;
}
.width-large{
  width:1024px;
}
.width-fill{
  width:100%;
}

.atw-modal {
  border-radius: 0.65rem;
  overflow: hidden;
}

.atw-modal-body {
  padding: 8px;

}

.atw-modal-header {
  padding: 16px;
  background-color: var(--darker);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.atw-modal-header-title {

}

.atw-modal-header-options {

}

.atw-modal-footer {
  padding: 8px;
  display: flex;
  justify-content: space-between;
}

.atw-modal-footer-start {
}

.atw-modal-footer-end {
  display: flex;
  gap: 8px;
}

.sidebar-modal .modal-dialog{
  width:100%;
  height:100%;
  margin:0;
}

.sidebar-modal .modal-content{
  height:100vh;
  border-radius:0;
  //animation: slideIn 0.5s ease forwards;
}

.sidebar-modal .modal-dialog{
  position:fixed;
  inset-inline-end: 0;
  top:0;
  height:100%;
  max-height:100%;
}

/* Slide in animation */
@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Slide out animation */
@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

app-sidebar-item{
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
}

.side-actionbar{
  height:var(--sidebar-header-height);
  display:flex;
  align-items:center;
  background-color: var(--darker);
  padding: 4px;
  border-top-left-radius:var(--radius-large);
  border-top-right-radius:var(--radius-large);
}
