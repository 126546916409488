//
//--nav-background: --naviblue-light-10;
//--nav-color: --naviblue-dark-5;

//--header-background: --naviblue-light-10;
//--header-color: --naviblue-dark-5;

//--nav-background: --naviblue;
//--nav-color: --naviblue-light-8;

//--header-background: --naviblue;
//--header-color: --naviblue-light-8;


:root {
  --color-1: #2a4d5e; // Blue (Base)
  --color-2: #486d80; // Blue (Lighter)
  --color-3: #339979; // Green (Base)
  --color-4: #28785f; // Green (Darker)
  --color-5: #e5394e; // Red (Base)
  --color-6: #c22d40; // Red (Darker)
  --color-7: #fb923c; // Orange (Base)
  --color-8: #fabe78; // Orange (Lighter)
  --color-9: #ffd55a; // Yellow
  --color-10: #ff6f84; // Pink
  --color-11: #9b4d89; // Purple
  --color-12: #7a8992; // Gray (Base)
  --color-13: #4c5961; // Gray (Darker)
  --color-14: #8b5e34; // Brown
  --color-15: #6e4a2b; // Brown (Darker)
  --color-16: #7fbba0; // Green (Muted)

  --color-red-50: oklch(0.971 0.013 17.38);
  --color-red-100: oklch(0.936 0.032 17.717);
  --color-red-200: oklch(0.885 0.062 18.334);
  --color-red-300: oklch(0.808 0.114 19.571);
  --color-red-400: oklch(0.704 0.191 22.216);
  --color-red-500: oklch(0.637 0.237 25.331);
  --color-red-600: oklch(0.577 0.245 27.325);
  --color-red-700: oklch(0.505 0.213 27.518);
  --color-red-800: oklch(0.444 0.177 26.899);
  --color-red-900: oklch(0.396 0.141 25.723);
  --color-red-950: oklch(0.258 0.092 26.042);
  --color-orange-50: oklch(0.98 0.016 73.684);
  --color-orange-100: oklch(0.954 0.038 75.164);
  --color-orange-200: oklch(0.901 0.076 70.697);
  --color-orange-300: oklch(0.837 0.128 66.29);
  --color-orange-400: oklch(0.75 0.183 55.934);
  --color-orange-500: oklch(0.705 0.213 47.604);
  --color-orange-600: oklch(0.646 0.222 41.116);
  --color-orange-700: oklch(0.553 0.195 38.402);
  --color-orange-800: oklch(0.47 0.157 37.304);
  --color-orange-900: oklch(0.408 0.123 38.172);
  --color-orange-950: oklch(0.266 0.079 36.259);
  --color-amber-50: oklch(0.987 0.022 95.277);
  --color-amber-100: oklch(0.962 0.059 95.617);
  --color-amber-200: oklch(0.924 0.12 95.746);
  --color-amber-300: oklch(0.879 0.169 91.605);
  --color-amber-400: oklch(0.828 0.189 84.429);
  --color-amber-500: oklch(0.769 0.188 70.08);
  --color-amber-600: oklch(0.666 0.179 58.318);
  --color-amber-700: oklch(0.555 0.163 48.998);
  --color-amber-800: oklch(0.473 0.137 46.201);
  --color-amber-900: oklch(0.414 0.112 45.904);
  --color-amber-950: oklch(0.279 0.077 45.635);
  --color-yellow-50: oklch(0.987 0.026 102.212);
  --color-yellow-100: oklch(0.973 0.071 103.193);
  --color-yellow-200: oklch(0.945 0.129 101.54);
  --color-yellow-300: oklch(0.905 0.182 98.111);
  --color-yellow-400: oklch(0.852 0.199 91.936);
  --color-yellow-500: oklch(0.795 0.184 86.047);
  --color-yellow-600: oklch(0.681 0.162 75.834);
  --color-yellow-700: oklch(0.554 0.135 66.442);
  --color-yellow-800: oklch(0.476 0.114 61.907);
  --color-yellow-900: oklch(0.421 0.095 57.708);
  --color-yellow-950: oklch(0.286 0.066 53.813);
  --color-lime-50: oklch(0.986 0.031 120.757);
  --color-lime-100: oklch(0.967 0.067 122.328);
  --color-lime-200: oklch(0.938 0.127 124.321);
  --color-lime-300: oklch(0.897 0.196 126.665);
  --color-lime-400: oklch(0.841 0.238 128.85);
  --color-lime-500: oklch(0.768 0.233 130.85);
  --color-lime-600: oklch(0.648 0.2 131.684);
  --color-lime-700: oklch(0.532 0.157 131.589);
  --color-lime-800: oklch(0.453 0.124 130.933);
  --color-lime-900: oklch(0.405 0.101 131.063);
  --color-lime-950: oklch(0.274 0.072 132.109);
  --color-green-50: oklch(0.982 0.018 155.826);
  --color-green-100: oklch(0.962 0.044 156.743);
  --color-green-200: oklch(0.925 0.084 155.995);
  --color-green-300: oklch(0.871 0.15 154.449);
  --color-green-400: oklch(0.792 0.209 151.711);
  --color-green-500: oklch(0.723 0.219 149.579);
  --color-green-600: oklch(0.627 0.194 149.214);
  --color-green-700: oklch(0.527 0.154 150.069);
  --color-green-800: oklch(0.448 0.119 151.328);
  --color-green-900: oklch(0.393 0.095 152.535);
  --color-green-950: oklch(0.266 0.065 152.934);
  --color-emerald-50: oklch(0.979 0.021 166.113);
  --color-emerald-100: oklch(0.95 0.052 163.051);
  --color-emerald-200: oklch(0.905 0.093 164.15);
  --color-emerald-300: oklch(0.845 0.143 164.978);
  --color-emerald-400: oklch(0.765 0.177 163.223);
  --color-emerald-500: oklch(0.696 0.17 162.48);
  --color-emerald-600: oklch(0.596 0.145 163.225);
  --color-emerald-700: oklch(0.508 0.118 165.612);
  --color-emerald-800: oklch(0.432 0.095 166.913);
  --color-emerald-900: oklch(0.378 0.077 168.94);
  --color-emerald-950: oklch(0.262 0.051 172.552);
  --color-teal-50: oklch(0.984 0.014 180.72);
  --color-teal-100: oklch(0.953 0.051 180.801);
  --color-teal-200: oklch(0.91 0.096 180.426);
  --color-teal-300: oklch(0.855 0.138 181.071);
  --color-teal-400: oklch(0.777 0.152 181.912);
  --color-teal-500: oklch(0.704 0.14 182.503);
  --color-teal-600: oklch(0.6 0.118 184.704);
  --color-teal-700: oklch(0.511 0.096 186.391);
  --color-teal-800: oklch(0.437 0.078 188.216);
  --color-teal-900: oklch(0.386 0.063 188.416);
  --color-teal-950: oklch(0.277 0.046 192.524);
  --color-cyan-50: oklch(0.984 0.019 200.873);
  --color-cyan-100: oklch(0.956 0.045 203.388);
  --color-cyan-200: oklch(0.917 0.08 205.041);
  --color-cyan-300: oklch(0.865 0.127 207.078);
  --color-cyan-400: oklch(0.789 0.154 211.53);
  --color-cyan-500: oklch(0.715 0.143 215.221);
  --color-cyan-600: oklch(0.609 0.126 221.723);
  --color-cyan-700: oklch(0.52 0.105 223.128);
  --color-cyan-800: oklch(0.45 0.085 224.283);
  --color-cyan-900: oklch(0.398 0.07 227.392);
  --color-cyan-950: oklch(0.302 0.056 229.695);
  --color-sky-50: oklch(0.977 0.013 236.62);
  --color-sky-100: oklch(0.951 0.026 236.824);
  --color-sky-200: oklch(0.901 0.058 230.902);
  --color-sky-300: oklch(0.828 0.111 230.318);
  --color-sky-400: oklch(0.746 0.16 232.661);
  --color-sky-500: oklch(0.685 0.169 237.323);
  --color-sky-600: oklch(0.588 0.158 241.966);
  --color-sky-700: oklch(0.5 0.134 242.749);
  --color-sky-800: oklch(0.443 0.11 240.79);
  --color-sky-900: oklch(0.391 0.09 240.876);
  --color-sky-950: oklch(0.293 0.066 243.157);
  --color-blue-50: oklch(0.97 0.014 254.604);
  --color-blue-100: oklch(0.932 0.032 255.585);
  --color-blue-200: oklch(0.882 0.059 254.128);
  --color-blue-300: oklch(0.809 0.105 251.813);
  --color-blue-400: oklch(0.707 0.165 254.624);
  --color-blue-500: oklch(0.623 0.214 259.815);
  --color-blue-600: oklch(0.546 0.245 262.881);
  --color-blue-700: oklch(0.488 0.243 264.376);
  --color-blue-800: oklch(0.424 0.199 265.638);
  --color-blue-900: oklch(0.379 0.146 265.522);
  --color-blue-950: oklch(0.282 0.091 267.935);
  --color-indigo-50: oklch(0.962 0.018 272.314);
  --color-indigo-100: oklch(0.93 0.034 272.788);
  --color-indigo-200: oklch(0.87 0.065 274.039);
  --color-indigo-300: oklch(0.785 0.115 274.713);
  --color-indigo-400: oklch(0.673 0.182 276.935);
  --color-indigo-500: oklch(0.585 0.233 277.117);
  --color-indigo-600: oklch(0.511 0.262 276.966);
  --color-indigo-700: oklch(0.457 0.24 277.023);
  --color-indigo-800: oklch(0.398 0.195 277.366);
  --color-indigo-900: oklch(0.359 0.144 278.697);
  --color-indigo-950: oklch(0.257 0.09 281.288);
  --color-violet-50: oklch(0.969 0.016 293.756);
  --color-violet-100: oklch(0.943 0.029 294.588);
  --color-violet-200: oklch(0.894 0.057 293.283);
  --color-violet-300: oklch(0.811 0.111 293.571);
  --color-violet-400: oklch(0.702 0.183 293.541);
  --color-violet-500: oklch(0.606 0.25 292.717);
  --color-violet-600: oklch(0.541 0.281 293.009);
  --color-violet-700: oklch(0.491 0.27 292.581);
  --color-violet-800: oklch(0.432 0.232 292.759);
  --color-violet-900: oklch(0.38 0.189 293.745);
  --color-violet-950: oklch(0.283 0.141 291.089);
  --color-purple-50: oklch(0.977 0.014 308.299);
  --color-purple-100: oklch(0.946 0.033 307.174);
  --color-purple-200: oklch(0.902 0.063 306.703);
  --color-purple-300: oklch(0.827 0.119 306.383);
  --color-purple-400: oklch(0.714 0.203 305.504);
  --color-purple-500: oklch(0.627 0.265 303.9);
  --color-purple-600: oklch(0.558 0.288 302.321);
  --color-purple-700: oklch(0.496 0.265 301.924);
  --color-purple-800: oklch(0.438 0.218 303.724);
  --color-purple-900: oklch(0.381 0.176 304.987);
  --color-purple-950: oklch(0.291 0.149 302.717);
  --color-fuchsia-50: oklch(0.977 0.017 320.058);
  --color-fuchsia-100: oklch(0.952 0.037 318.852);
  --color-fuchsia-200: oklch(0.903 0.076 319.62);
  --color-fuchsia-300: oklch(0.833 0.145 321.434);
  --color-fuchsia-400: oklch(0.74 0.238 322.16);
  --color-fuchsia-500: oklch(0.667 0.295 322.15);
  --color-fuchsia-600: oklch(0.591 0.293 322.896);
  --color-fuchsia-700: oklch(0.518 0.253 323.949);
  --color-fuchsia-800: oklch(0.452 0.211 324.591);
  --color-fuchsia-900: oklch(0.401 0.17 325.612);
  --color-fuchsia-950: oklch(0.293 0.136 325.661);
  --color-pink-50: oklch(0.971 0.014 343.198);
  --color-pink-100: oklch(0.948 0.028 342.258);
  --color-pink-200: oklch(0.899 0.061 343.231);
  --color-pink-300: oklch(0.823 0.12 346.018);
  --color-pink-400: oklch(0.718 0.202 349.761);
  --color-pink-500: oklch(0.656 0.241 354.308);
  --color-pink-600: oklch(0.592 0.249 0.584);
  --color-pink-700: oklch(0.525 0.223 3.958);
  --color-pink-800: oklch(0.459 0.187 3.815);
  --color-pink-900: oklch(0.408 0.153 2.432);
  --color-pink-950: oklch(0.284 0.109 3.907);
  --color-rose-50: oklch(0.969 0.015 12.422);
  --color-rose-100: oklch(0.941 0.03 12.58);
  --color-rose-200: oklch(0.892 0.058 10.001);
  --color-rose-300: oklch(0.81 0.117 11.638);
  --color-rose-400: oklch(0.712 0.194 13.428);
  --color-rose-500: oklch(0.645 0.246 16.439);
  --color-rose-600: oklch(0.586 0.253 17.585);
  --color-rose-700: oklch(0.514 0.222 16.935);
  --color-rose-800: oklch(0.455 0.188 13.697);
  --color-rose-900: oklch(0.41 0.159 10.272);
  --color-rose-950: oklch(0.271 0.105 12.094);
  --color-slate-50: oklch(0.984 0.003 247.858);
  --color-slate-100: oklch(0.968 0.007 247.896);
  --color-slate-200: oklch(0.929 0.013 255.508);
  --color-slate-300: oklch(0.869 0.022 252.894);
  --color-slate-400: oklch(0.704 0.04 256.788);
  --color-slate-500: oklch(0.554 0.046 257.417);
  --color-slate-600: oklch(0.446 0.043 257.281);
  --color-slate-700: oklch(0.372 0.044 257.287);
  --color-slate-800: oklch(0.279 0.041 260.031);
  --color-slate-900: oklch(0.208 0.042 265.755);
  --color-slate-950: oklch(0.129 0.042 264.695);
  --color-gray-50: oklch(0.985 0.002 247.839);
  --color-gray-100: oklch(0.967 0.003 264.542);
  --color-gray-200: oklch(0.928 0.006 264.531);
  --color-gray-300: oklch(0.872 0.01 258.338);
  --color-gray-400: oklch(0.707 0.022 261.325);
  --color-gray-500: oklch(0.551 0.027 264.364);
  --color-gray-600: oklch(0.446 0.03 256.802);
  --color-gray-700: oklch(0.373 0.034 259.733);
  --color-gray-800: oklch(0.278 0.033 256.848);
  --color-gray-900: oklch(0.21 0.034 264.665);
  --color-gray-950: oklch(0.13 0.028 261.692);
  --color-zinc-50: oklch(0.985 0 0);
  --color-zinc-100: oklch(0.967 0.001 286.375);
  --color-zinc-200: oklch(0.92 0.004 286.32);
  --color-zinc-300: oklch(0.871 0.006 286.286);
  --color-zinc-400: oklch(0.705 0.015 286.067);
  --color-zinc-500: oklch(0.552 0.016 285.938);
  --color-zinc-600: oklch(0.442 0.017 285.786);
  --color-zinc-700: oklch(0.37 0.013 285.805);
  --color-zinc-800: oklch(0.274 0.006 286.033);
  --color-zinc-900: oklch(0.21 0.006 285.885);
  --color-zinc-950: oklch(0.141 0.005 285.823);
  --color-neutral-50: oklch(0.985 0 0);
  --color-neutral-100: oklch(0.97 0 0);
  --color-neutral-200: oklch(0.922 0 0);
  --color-neutral-300: oklch(0.87 0 0);
  --color-neutral-400: oklch(0.708 0 0);
  --color-neutral-500: oklch(0.556 0 0);
  --color-neutral-600: oklch(0.439 0 0);
  --color-neutral-700: oklch(0.371 0 0);
  --color-neutral-800: oklch(0.269 0 0);
  --color-neutral-900: oklch(0.205 0 0);
  --color-neutral-950: oklch(0.145 0 0);
  --color-stone-50: oklch(0.985 0.001 106.423);
  --color-stone-100: oklch(0.97 0.001 106.424);
  --color-stone-200: oklch(0.923 0.003 48.717);
  --color-stone-300: oklch(0.869 0.005 56.366);
  --color-stone-400: oklch(0.709 0.01 56.259);
  --color-stone-500: oklch(0.553 0.013 58.071);
  --color-stone-600: oklch(0.444 0.011 73.639);
  --color-stone-700: oklch(0.374 0.01 67.558);
  --color-stone-800: oklch(0.268 0.007 34.298);
  --color-stone-900: oklch(0.216 0.006 56.043);
  --color-stone-950: oklch(0.147 0.004 49.25);



  --green: #008236;
  --green-dark-1: #2e8a6d;
  --green-dark-2: #297a61;
  --green-dark-3: #246b55;
  --green-dark-4: #1f5c49;
  --green-dark-5: #1a4d3d;
  --green-dark-6: #143d30;
  --green-dark-7: #0f2e24;
  --green-dark-8: #0a1f18;
  --green-dark-9: #050f0c;
  --green-light-1: #47a386;
  --green-light-2: #5cad94;
  --green-light-3: #70b8a1;
  --green-light-4: #85c2af;
  --green-light-5: #99ccbc;
  --green-light-6: #add6c9;
  --green-light-7: #c2e0d7;
  --green-light-8: #d6ebe4;
  --green-light-9: #ebf5f2;

  //--orange: #f6921e;
  --orange: #ff6900;
  --orange-transparent: #ff69001a;
  --orange-dark-1: #dd831b;
  --orange-dark-2: #c57518;
  --orange-dark-3: #ac6615;
  --orange-dark-4: #ac6615;
  --orange-dark-5: #945812;
  --orange-dark-6: #7b490f;
  --orange-dark-7: #623a0c;
  --orange-dark-8: #4a2c09;
  --orange-dark-9: #311d06;
  --orange-light-1: #f79d35;
  --orange-light-2: #f8a84b;
  --orange-light-3: #f9b362;
  --orange-light-4: #fabe78;
  --orange-light-5: #fbc98f;
  --orange-light-6: #fbd3a5;
  --orange-light-7: #fcdebc;
  --orange-light-8: #fde9d2;
  --orange-light-9: #fef4e9;

  --red: #ef4444;
  --red-dark-1: #ce3346;
  --red-dark-2: #b72e3e;
  --red-dark-3: #a02837;
  --red-dark-4: #89222f;
  --red-dark-5: #731d27;
  --red-dark-6: #5c171f;
  --red-dark-7: #451117;
  --red-dark-8: #2e0b10;
  --red-dark-9: #170608;
  --red-light-1: #e84d60;
  --red-light-2: #ea6171;
  --red-light-3: #f87171;
  --red-light-4: #ef8895;
  --red-light-5: #f29ca7;
  --red-light-6: #f5b0b8;
  --red-light-7: #f7c4ca;
  --red-light-8: #fad7dc;
  --red-light-9: #fcebed;

  --naviblue-dark-1: #12394c;
  --naviblue-dark-2: #103344;
  --naviblue-dark-3: #0e2e3d;
  --naviblue-dark-4: #0d2835;
  --naviblue-dark-5: #0b222e;
  --naviblue-dark-6: #091d26;
  --naviblue-dark-7: #07171e;
  --naviblue-dark-8: #051117;
  --naviblue-dark-9: #040b0f;
  --naviblue: #2a4d5e;
  --naviblue-light-1: #416170;
  --naviblue-light-2: #597482;
  --naviblue-light-3: #718894;
  --naviblue-light-4: #899ca6;
  --naviblue-light-5: #a0b0b7;
  --naviblue-light-6: #b8c4c9;
  --naviblue-light-7: #d0d7db;
  --naviblue-light-8: #e7ebed;
  --naviblue-light-9: #edf2f6;
  --naviblue-light-10: #f5f7f9;
  --naviblue-light-11: #f5f9fb;


  --blue-50: #eff6ff;
  --blue-100: #dbeafe;
  --blue-200: #bfdbfe;
  --blue-300: #93c5fd;
  --blue-400: #60a5fa;
  --blue-500: #3b82f6;
  --blue-600: #2563eb;
  --blue-700: #1d4ed8;
  --blue-800: #1e40af;
  --blue-900: #1e3a8a;
  --blue-950: #172554;

  --cyan-50: #ecfeff;
  --cyan-100: #cffafe;
  --cyan-200: #a5f3fc;
  --cyan-300: #67e8f9;
  --cyan-400: #22d3ee;
  --cyan-500: #06b6d4;
  --cyan-600: #0891b2;
  --cyan-700: #0e7490;
  --cyan-800: #155e75;
  --cyan-900: #164e63;
  --cyan-950: #083344;


  --gray: var(--naviblue-light-9);

  --active-background: var(--naviblue-light-2);
  --transparent: #ffffff12;
  --gray-half-transparent: #16161666;


  --active-color: var(--secondary-color);
  --input-background: var(--naviblue-light-10);
  --hover-transparent: #e7ebed33;


}

.dark-theme {

  --primary-color: var(--naviblue-dark-2);
  --primary-opposite-color: var(--naviblue-light-8);
  --btn-primary-color: var(--cyan-700);
  --focus-border-color: var(--cyan-700);
  --secondary-color: var(--orange);
  --body-bg: linear-gradient(0deg, var(--naviblue-dark-8), var(--naviblue-dark-5));
  --body-bg-bb: #051117bb;
  --footer-bg: var(--naviblue-dark-5);
  --bg-solid: #051117;

  --nav-background: transparent;
  --header-background: transparent;
  --border-color: var(--naviblue-dark-2);
  --nav-border-color: var(--naviblue-light-1);

  --box-title-border: var(--naviblue-dark-1);

  --accent-color: var(--naviblue-dark-2);

  --body-color: var(--naviblue-light-8);
  --fade-text-color: var(--naviblue-light-5);
  --strong-text-color: white;

  --card-bg: #0e2e3d77;
  --dropdown-bg: #0b222eee;
  --modal-bg: #0b222eee;
  --modal-header-bg: #091d26ee;
  --tooltip-bg: #0b222ef5;
  --nav-color: var(--naviblue-light-8);
  --header-color: var(--naviblue-light-8);
  --darker-06: #a0b0b706;
  --darker: #a0b0b712;
  --darker2: #a0b0b722;
  --darker3: #a0b0b733;
  --darker4: #a0b0b744;
  --darker-a: #a0b0b7aa;
  --lighter: #091d2612;
  --lighter2: #091d2622;
  --lighter3: #091d2633;
  --lighter4: #091d2644;
  --lighter-a: #091d26aa;

  --table-header-bg: #0b2430;
  --table-row-bg: #0b212c;
  --table-header-color: var(--naviblue-light-8);
  --table-cell-border-color: var(--naviblue-dark-4);
  --table-th-border-color: var(--naviblue);
  --table-row-hover: var(--naviblue-dark-2);
  --shimmer-dark-color: #71889433;
  --shimmer-dark-back: #d0d7db33;
  --shimmer-color: #71889433;
  --shimmer-back: #d0d7db33;

  --hover-back: var(--darker2);
  --input-bg: var(--darker2);
  --input-color: var(--naviblue-light-8);
  --input-border: var(--naviblue-dark-2);
  --message-input-bg: var(--naviblue-dark-2);
  --checkbox-bg: var(--naviblue-light-3);
  --checkbox-border: var(--naviblue-light-3);

  --box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .03);

  --shadow-color: rgb(110 110 110 /0.1);

  --dropdown-border: 1px solid var(--border-color);

  --scroll-bg: #2a4d5e55;
  --scroll-color: #2a4d5e;

  --editor-bg: #a0b0b706;

}

.light-theme {

  --primary-color: var(--naviblue-light-2);
  --primary-opposite-color: var(--naviblue-light-9);
  --btn-primary-color: var(--blue-400);
  --focus-border-color: var(--blue-400);
  --secondary-color: var(--orange);
  --shadow-color: rgb(0 0 0 /0.1);

  --body-bg: linear-gradient(0deg, #e1e1e1, #f5f7f9);
  --body-bg-bb: #e1e1e1bb;
  --footer-bg: #f5f7f9;
  --bg-solid: #e1e1e1;

  --body-color: var(--naviblue-dark-3);
  --fade-text-color: var(--naviblue-light-3);
  --strong-text-color: var(--naviblue-dark-9);
  --hover-back: var(--naviblue-light-8);

  --box-title-border: var(--naviblue-light-7);

  --nav-color: var(--naviblue-light-1);
  --header-color: var(--naviblue-light-1);
  --nav-background: #f5f7f9;
  --header-background: transparent;
  --border-color: var(--naviblue-light-7);
  --nav-border-color: var(--naviblue-light-7);
  --card-bg: #fcfcfc77;
  --dropdown-bg: #fcfcfcee;
  --modal-bg: #f5f5f5dd;
  --modal-header-bg: #f0f0f0ee;
  --tooltip-bg: #f5f5f5f5;
  --darker-06: #12394c06;
  --darker: #12394c12;
  --darker2: #12394c22;
  --darker3: #12394c33;
  --darker4: #12394c44;
  --darker-a: #12394caa;
  --lighter: #ffffff12;
  --lighter2: #ffffff22;
  --lighter3: #ffffff33;
  --lighter4: #ffffff44;
  --lighter-a: #ffffffaa;
  --checkbox-bg: var(--border-color);
  --checkbox-border: var(--border-color);
  --table-header-bg: #f5f7f9;
  --table-row-bg: white;
  --table-border: 1px solid var(--naviblue-light-8);
  --table-header-color: var(--naviblue-light-1);
  --table-cell-border-color: var(--naviblue-light-10);
  --table-th-border-color: var(--naviblue-light-8);
  --table-row-hover: var(--naviblue-light-9);

  --accent-color: var(--naviblue-light-7);

  --sheet-submenu-color: #f5f7f9;

  --shimmer-color: #718894ee;
  --shimmer-back: #d0d7dbee;
  --shimmer-dark-color: #718894ee;
  --shimmer-dark-back: #d0d7dbee;
  --input-bg: var(--darker);
  --input-color: var(--naviblue-dark-1);
  --input-border: var(--naviblue-light-7);
  --message-input-bg: var(--naviblue-light-8);

  --box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .03);

  --dropdown-border: 1px solid var(--border-color);

  --scroll-bg: #e7ebed55;
  --scroll-color: #b8c4c9;

  --editor-bg: #12394c06;

}

.dark-theme * {
  color-scheme: dark;
}

.light-theme * {
  color-scheme: light;
}

.editor-bg {
  background: var(--editor-bg);
}

.modal-bg {
  background-color: var(--modal-bg);
  backdrop-filter: blur(var(--backdrop-blur));
}

.card-bg {
  background-color: var(--card-bg);
  backdrop-filter: blur(var(--backdrop-blur));
}

.backdrop-blur {
  backdrop-filter: blur(var(--backdrop-blur));
}

.bg-gradient {
  background: linear-gradient(0deg, var(--bg-solid), transparent);
}

.bg-solid {
  background: var(--bg-solid);
}

.bg-green {
  background-color: var(--green);
}

.bg-red {
  background-color: var(--red);
}

.bg-white {
  background-color: white;
}

.green {
  color: var(--green);
}

.gray {
  color: var(--naviblue-light-3);
}

.orange {
  color: var(--orange);
}

.red {
  color: var(--red);
}

.white {
  color: #ffffff;
}

.naviblue {
  color: var(--naviblue);
}

.naviblue-light {
  color: var(--naviblue-light-3);
}

.bg-naviblue-light-2 {
  background-color: var(--naviblue-light-2) !important;
}

.cyan-500 {
  color: var(--cyan-500);
}

.bg-table-header {
  background-color: var(--table-header-bg) !important;

}

.hover-transparent:hover {
  background-color: var(--transparent);
}

.hover-default:hover {
  background-color: var(--darker2);
  border-radius: 4px;
}

.hover-red:hover {
  cursor: pointer;
  color: var(--red);
}

.hover-opacity {
  opacity: 0.7;
}

.hover-opacity:hover {
  opacity: 0.9 !important;
}

.hover-opacity:active {
  opacity: 1;
}

.fade-text-color {
  color: var(--fade-text-color);
}

.naviblue-lighter {
  color: var(--naviblue-light-6);
}

.naviblue-light-9 {
  color: var(--naviblue-light-9);
}

.box-shadow {
  //box-shadow: 0 0 5px 4px rgba(--naviblue-light-9, 0.6);
  box-shadow: var(--box-shadow);
}

.box-shadow-hover:hover {
  box-shadow: var(--box-shadow);
}


.border {
  border: 1px solid var(--border-color);
}

.border-orange {
  border: 1px solid var(--orange);
}

.border-red {
  border: 1px solid var(--red);
}

.bg-lighter {
  background-color: var(--lighter);
}

.bg-lighter-a {
  background-color: var(--lighter-a);
}

.bg-darker {
  background-color: var(--darker);
}

.bg-darker-a {
  background-color: var(--darker-a);
}

.bg-darker2 {
  background-color: var(--darker2);
}

.bg-darker2-hover:hover {
  background-color: var(--darker2);
}

.bg-orange {
  background-color: var(--orange);
}

.bg-orange-hover:hover{
  background-color: var(--orange);
}

.bg-orange-transparent {
  background-color: var(--orange-transparent);
}

.shimmer {
  color: var(--shimmer-color);
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) (right / 300%) 100%;
  background-repeat: no-repeat;
  animation: shimmer 3s infinite;
  font-size: 50px;
  background-color: var(--shimmer-back);
}
.thinking {
  color: var(--shimmer-color);
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) (right / 300%) 100%;
  background-repeat: no-repeat;
  animation: shimmer 1s infinite;
}

.shimmer-dark {
  color: var(--shimmer-dark-color);
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) (right / 300%) 100%;
  background-repeat: no-repeat;
  animation: shimmer 3s infinite;
  font-size: 50px;
  background-color: var(--shimmer-dark-back);
}


//@keyframes shimmer {
//  100% {
//    -webkit-mask-position: left;
//  }
//}

@keyframes shimmer {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {

    opacity: 0.5;
  }
}

.color-1 {
  color: var(--color-1);
}

.color-2 {
  color: var(--color-2);
}

.color-3 {
  color: var(--color-3);
}

.color-4 {
  color: var(--color-4);
}

.color-5 {
  color: var(--color-5);
}

.color-6 {
  color: var(--color-6);
}

.color-7 {
  color: var(--color-7);
}

.color-8 {
  color: var(--color-8);
}

.color-9 {
  color: var(--color-9);
}

.color-10 {
  color: var(--color-10);
}

.color-11 {
  color: var(--color-11);
}

.color-12 {
  color: var(--color-12);
}

.color-13 {
  color: var(--color-13);
}

.color-14 {
  color: var(--color-14);
}

.color-15 {
  color: var(--color-15);
}

.color-16 {
  color: var(--color-16);
}

.bg-color-1 {
  background-color: var(--color-1);
  color: white;
}

.bg-color-2 {
  background-color: var(--color-2);
  color: white;

}

.bg-color-3 {
  background-color: var(--color-3);
  color: white;

}

.bg-color-4 {
  background-color: var(--color-4);
  color: white;

}

.bg-color-5 {
  background-color: var(--color-5);
  color: white;

}

.bg-color-6 {
  background-color: var(--color-6);
  color: white;

}

.bg-color-7 {
  background-color: var(--color-7);
  color: white;

}

.bg-color-8 {
  background-color: var(--color-8);
  color: white;

}

.bg-color-9 {
  background-color: var(--color-9);
  color: white;

}

.bg-color-10 {
  background-color: var(--color-10);
  color: white;

}

.bg-color-11 {
  background-color: var(--color-11);
  color: white;

}

.bg-color-12 {
  background-color: var(--color-12);
  color: white;

}

.bg-color-13 {
  color: white;
  background-color: var(--color-13);
}

.bg-color-14 {
  color: white;
  background-color: var(--color-14);
}

.bg-color-15 {
  color: white;
  background-color: var(--color-15);
}

.bg-color-16 {
  color: white;
  background-color: var(--color-16);
}

.bg-color-17 {
  background-color: var(--naviblue-light-9);
  color: var(--naviblue-dark-9);
}

.body-bg {
  background: var(--body-bg);
}


.color-picker {
  background-color: var(--dropdown-bg) !important;
  border-radius: var(--radius-large) !important;
  border: 1px solid var(--border-color) !important;
  backdrop-filter: blur(var(--backdrop-blur));
}

.color-picker .saturation-lightness {
  border-top-left-radius: var(--radius-large);
  border-top-right-radius: var(--radius-large);
}

.color-picker * {
  direction: ltr;
  border-color: var(--border-color);
}

.color-picker .button-area button {
  padding: 4px 16px;
  border-radius: var(--radius);
}

.color-picker .box input {
  background-color: var(--darker);
  color: var(--body-color);
  border: none;
  border-radius: var(--radius);
}

.dark-theme .emoji-mart-category .emoji-mart-emoji-custom span {
  filter: invert(1);
}
