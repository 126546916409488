@use 'sass:color';
@import 'src/assets/css/custom/color';
@import 'src/assets/css/custom/size';

.cdx-marker {
  padding: 3px !important;
  background-color: rgba(var(--orange), 0.5) !important;
  border-radius: var(--radius);
}

code.inline-code {
  color: var(--red-light-1) !important;
}

.ce-toolbar__content {
  max-width: initial;
}

.ce-block__content {
  max-width: initial;
}

.codex-editor {
  margin-inline-start: 70px;
  margin-inline-end: 70px;
}


data {
  background-color: var(--darker4);
  color: var(--orange-dark-1);
  padding: 0 8px;
  border-radius: 24px;
  font-style: italic;
  font-weight: 500;
}

//data[type=user]{
//  color:var(--green);
//}

.bn-container {
  overflow: hidden;
  height: 100% !important;
  //--bn-font-family: "Inter", "SF Pro Display", -apple-system, BlinkMacSystemFont, "Open Sans",
  //"Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  //"Helvetica Neue", sans-serif;
  --bn-font-family: "Poppins", Vazirmatn, 'sans-serif';

}

.bn-default-styles {
  font-family: "Poppins", Vazirmatn, 'sans-serif' !important;
}

.bn-editor {
  height: 100% !important;
  padding-inline: 36px;
  padding-top: 16px;
  padding-bottom: 32px;
  overflow: auto;
}

.alert {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  border-radius: 4px;
  min-height: 48px;
  padding: 4px;
}

.alert[data-alert-type="warning"] {
  background-color: #805d20;
  color:#ffffff;
}

.alert[data-alert-type="error"] {
  background-color: #802020;
  color:#ffffff;
}

.alert[data-alert-type="info"] {
  background-color: #203380;
  color:#ffffff;
}

.alert[data-alert-type="success"] {
  background-color: #208020;
  color:#ffffff;
}

.alert-icon-wrapper {
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  margin-right: 12px;
  height: 18px;
  width: 18px;
  user-select: none;
  cursor: pointer;
}

.alert-icon[data-alert-icon-type="warning"] {
  color: #e69819
}

.alert-icon[data-alert-icon-type="error"] {
  color: #d80d0d
}

.alert-icon[data-alert-icon-type="info"] {
  color: #507aff
}

.alert-icon[data-alert-icon-type="success"] {
  color: #0bc10b
}

.inline-content {
  flex-grow: 1;
}

.bn-block-content div, .bn-block-content p, .bn-block-content span {
  font-size: 1.2rem !important;
}
.bn-block-outer{
  line-height:1.8;
}
.bn-default-styles{
  font-weight:300;
}

.dark-theme .bn-container[data-color-scheme]{
  --bn-colors-editor-text: #e7ebed;
  --bn-colors-editor-background: #a0b0b700;
  --bn-colors-menu-text: #e7ebed;
  --bn-colors-menu-background: #0b222ef2;
  --bn-colors-tooltip-text: #3f3f3f;
  --bn-colors-tooltip-background: #efefef;
  --bn-colors-hovered-text: #e7ebed;
  --bn-colors-hovered-background: #a0b0b712;
  --bn-colors-selected-text: #e7ebed;
  --bn-colors-selected-background: #3f3f3f;
  --bn-colors-disabled-text: #afafaf;
  --bn-colors-disabled-background: #efefef;
  --bn-colors-border: #103344;
  //
  --bn-colors-shadow: #103344;
  --bn-colors-side-menu: #cfcfcf;
  --bn-colors-highlights-gray-text: #9b9a97;
  --bn-colors-highlights-gray-background: #ebeced;
  --bn-colors-highlights-brown-text: #64473a;
  --bn-colors-highlights-brown-background: #e9e5e3;
  --bn-colors-highlights-red-text: #e03e3e;
  --bn-colors-highlights-red-background: #fbe4e4;
  --bn-colors-highlights-orange-text: #d9730d;
  --bn-colors-highlights-orange-background: #f6e9d9;
  --bn-colors-highlights-yellow-text: #dfab01;
  --bn-colors-highlights-yellow-background: #fbf3db;
  --bn-colors-highlights-green-text: #4d6461;
  --bn-colors-highlights-green-background: #ddedea;
  --bn-colors-highlights-blue-text: #0b6e99;
  --bn-colors-highlights-blue-background: #ddebf1;
  --bn-colors-highlights-purple-text: #6940a5;
  --bn-colors-highlights-purple-background: #eae4f2;
  --bn-colors-highlights-pink-text: #ad1a72;
  --bn-colors-highlights-pink-background: #f4dfeb;
}

.light-theme .bn-container[data-color-scheme]{
  --bn-colors-editor-text: #0e2e3d;
  --bn-colors-editor-background: #12394c00;
  --bn-colors-menu-text: #0e2e3d;
  --bn-colors-menu-background: #fcfcfcf2;
  --bn-colors-tooltip-text: #0e2e3d;
  --bn-colors-tooltip-background: #fcfcfcee;
  --bn-colors-hovered-text: #0e2e3d;
  --bn-colors-hovered-background: #a0b0b712;
  --bn-colors-selected-text: #fcfcfc;
  --bn-colors-selected-background: #12394c80;
  --bn-colors-disabled-text: #a0a0a0;
  --bn-colors-disabled-background: #12394ccc;
  --bn-colors-border: #e7ebed;
  //
  --bn-colors-shadow: #cfcfcf;
  --bn-colors-side-menu: #cfcfcf;
  --bn-colors-highlights-gray-text: #9b9a97;
  --bn-colors-highlights-gray-background: #ebeced;
  --bn-colors-highlights-brown-text: #64473a;
  --bn-colors-highlights-brown-background: #e9e5e3;
  --bn-colors-highlights-red-text: #e03e3e;
  --bn-colors-highlights-red-background: #fbe4e4;
  --bn-colors-highlights-orange-text: #d9730d;
  --bn-colors-highlights-orange-background: #f6e9d9;
  --bn-colors-highlights-yellow-text: #dfab01;
  --bn-colors-highlights-yellow-background: #fbf3db;
  --bn-colors-highlights-green-text: #4d6461;
  --bn-colors-highlights-green-background: #ddedea;
  --bn-colors-highlights-blue-text: #0b6e99;
  --bn-colors-highlights-blue-background: #ddebf1;
  --bn-colors-highlights-purple-text: #6940a5;
  --bn-colors-highlights-purple-background: #eae4f2;
  --bn-colors-highlights-pink-text: #ad1a72;
  --bn-colors-highlights-pink-background: #f4dfeb;
}
