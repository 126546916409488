@keyframes rotating-shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-1deg);
  }
  50% {
    transform: rotate(1deg);
  }
  75% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}


@keyframes typing {
  from {
    width: 0
  }
  to {
    width: 100%
  }
}

@keyframes blink-caret {
  from, to {
    border-color: transparent
  }
  50% {
    border-color: orange;
  }
}


.processing {
  overflow: hidden;
  max-width: fit-content !important;
  border-right: .15em solid orange;
  white-space: nowrap;
  margin: 0 auto;
  animation: typing 3.5s steps(40, end),
  blink-caret .75s step-end infinite;
}


@keyframes atw-pulse {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1.3);
  }
}


@keyframes single-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes smooth-roam {
  0% {
    transform: translate(-10px, -10px);
  }
  25% {
    transform: translate(-5px, 5px);
  }
  50% {
    transform: translate(5px, 10px);
  }
  75% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.smooth-roam {
  animation: smooth-roam 6s ease infinite alternate;
}

.transition-all {
  transition: all 0.2s ease-in-out;
}

.transition-all-05 {
  transition: all 0.5s ease-in-out;
}

.loading-dots {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 2px;
}
//'submit' | 'red' | 'green' | 'neutral' |'cancel'| 'orange'
.button-loading-dots-submit span{
  background-color:white !important;
}
.button-loading-dots-orange span{
  background-color:white !important;
}

.loading-dots span {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  animation: atw-pulse 0.6s ease-in-out infinite alternate;
}

.loading-dots.size-large span {
  width: 8px;
  height: 8px;
}

.loading-dots.size-xlarge span {
  width: 12px;
  height: 12px;
}


.loading-dots span:nth-child(1) {
  animation-delay: 0s;
  background-color: var(--color-4);
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
  background-color: var(--color-9);
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
  background-color: var(--color-6);
}

.animation-delay-1 {
  animation-delay: 0.25s;
}

.animation-delay-2 {
  animation-delay: 0.5s;
}

.animation-delay-3 {
  animation-delay: 0.75s;
}

.animation-delay-4 {
  animation-delay: 1s;
}

.transform-origin-start {

}

.transform-origin-end {

}

html[dir='rtl'] .transform-origin-start {
  transform-origin: right;
}

html[dir='ltr'] .transform-origin-start {
  transform-origin: left;
}

html[dir='rtl'] .transform-origin-end {
  transform-origin: left;
}

html[dir='ltr'] .transform-origin-end {
  transform-origin: right;
}

.transition-icon-to-fit {
  transition: all .2s ease-in-out;
}

.transition-icon-to-fit :nth-child(2) {
  transition: all .2s ease-in-out;
  overflow: hidden;
  max-width: 0;
}

.transition-icon-to-fit:hover :nth-child(2) {
  max-width: 1000px;
}
