/*tapBar*/
@font-face {
  font-family: "Nunitio";
  src: url("/assets/fonts/Nunito/Nunito-Medium.ttf") format("truetype"); /* Safari, Android, iOS */
  font-weight: normal;
}
.header {
  height: 56px
}

.header .svg-icon.svg-icon-1 svg {
  height: 24px !important;
  width: 24px !important;
}

.header .btn-icon {
  width: 35px !important;
  height: 35px !important;
}

/*leftBar*/
/* div#kt_main_aside {
  width: 64px !important;
} */

#kt_main_aside .menu-icon i {
  font-size: 20px !important;
  /* width: 24px;
  height: 24px; */
}

#kt_main_aside .menu-title {
  font-size: 16px !important;
}

/*sub-menu*/
.aside.sub-menu {
  width: 240px !important;
}

/*footer*/
div#kt_footer > div {
  height: 32px;
}

h1, .h1 {
  font-size: 1.75rem !important;
}

h2, .h2 {
  font-size: 1.5rem !important;
}

h3, .h3 {
  font-size: 1.25rem !important;
}

h4, .h4 {
  font-size: 1.125rem !important;
}

h5, .h5 {
  font-size: 1rem !important;
}

h6, .h6 {
  font-size: 1rem !important;
}

p{
  font-size:1rem !important;
}

.header-comp .sub-tabs .sub-chat {
  font-size: 18px !important;
}

.profile-pic {
  width: 32px !important;
  height: 32px !important;
}

body {
  font-family: "Poppins", Vazirmatn, 'sans-serif' !important;
}

.w-24px {
  width: 24px;
  height: 24px;
}
