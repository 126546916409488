@import "src/assets/css/custom/color";
@import "src/assets/css/custom/size";


* {
  unicode-bidi: plaintext;
}

.blocknote-editor-rtl {
  direction: rtl;
}

.blocknote-editor-rtl * {
  unicode-bidi: initial !important;
}

html[dir='rtl'] .flip-horizontal {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
