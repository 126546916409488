.emoji-mart{
  background:var(--modal-bg) !important;
  color:var(--body-color)!important;
  border-color:var(--border-color)!important;
}

.emoji-mart-bar{
  border-color:var(--border-color)!important;
}

.emoji-mart-search input {
  background-color:var(--input-bg)!important;
  color:var(--body-color)!important;
  border-color:var(--border-color)!important;
}

.emoji-mart-category-label span{
  background-color:var(--tooltip-bg)!important;
  color:var(--body-color)!important;
}
.emoji-mart-anchor-selected{
  color:var(--orange)!important;
}

.emoji-mart-anchor-selected .emoji-mart-anchor-bar{
  background-color:var(--orange)!important;
}
