@use '@angular/material' as mat;

@include mat.core();


.light-theme{


$panel-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$panel-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

$panel-warn: mat.m2-define-palette(mat.$m2-red-palette);

$panel-theme: mat.m2-define-light-theme((
  color: (
    primary: $panel-primary,
    accent: $panel-accent,
    warn: $panel-warn,
  )
));
@include mat.all-component-themes($panel-theme);
}

.dark-theme{


  $panel-primary: mat.m2-define-palette(mat.$m2-gray-palette);
  $panel-accent: mat.m2-define-palette(mat.$m2-blue-gray-palette, A200, A100, A400);

  $panel-warn: mat.m2-define-palette(mat.$m2-red-palette);

  $panel-theme: mat.m2-define-dark-theme((
    color: (
      primary: $panel-primary,
      accent: $panel-accent,
      warn: $panel-warn,
    )
  ));
  @include mat.all-component-themes($panel-theme);
}
.mat-drawer-side.mat-drawer-end{
  border:none!important;
}
