:root {
  --node-background-color: transparent;
  --node-border-radius: 4px;
  --node-border-color: var(--border-color);
  --node-color: var(--body-color);
  --node-shadow: 0 1px 2px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.06);
  --connection-color: var(--orange);
  --snap-connection-color: var(--orange);
  --connection-gradient-1: var(--orange);
  --connection-gradient-2: var(--btn-primary-color);
  --outlet-color: #3451b2;
  --input-output-color: var(--orange);
  --minimap-background-color: var(--modal-bg);
  --minimap-node-color: rgba(60, 60, 67);
  --minimap-node-selected-color: var(--orange);
  --minimap-view-color: rgba(100, 108, 255, 0.14);
}
@mixin connection {
  .f-connection {
    .f-connection-drag-handle {
      fill: transparent;
    }
    .f-connection-selection {
      fill: none;
    }
    .f-connection-path {
      fill: none;
      stroke: var(--connection-color);
      stroke-width: 2;
    }
    .f-connection-text, .f-connection-center {
      fill: var(--connection-color);
      color: var(--connection-color);
    }
    &.f-snap-connection {
      .f-connection-path {
        stroke: var(--snap-connection-color);
      }
    }
  }
}
@mixin background {
  .f-background {
    line {
      stroke: var(--border-color);
    }
  }
}
@mixin line-alignment {
  .f-line-alignment {
    .f-line {
      background-color: var(--connection-color);
    }
  }
}
@mixin minimap {
  .f-minimap {
    background-color: var(--minimap-background-color);
    bottom: 16px;
    right: 16px;
    width: 120px;
    height: 120px;
    .f-minimap-node {
      fill: var(--minimap-node-color);
      &.f-selected {
        fill: var(--minimap-node-selected-color);
      }
    }
    .f-minimap-view {
      fill: var(--minimap-view-color)
    }
  }
}
@mixin node {
  width: 100px;
  padding: 4px;
  color: var(--node-color);
  text-align: center;
  background: var(--node-background-color);
  border-radius: var(--node-border-radius);
  border: 0.2px solid var(--node-border-color);
  cursor: move;
  &:active {
    box-shadow: var(--node-shadow);
  }
}
@mixin connectors {
  .connectors-line {
    position: absolute;
    display: flex;
    justify-content: center;
    gap: 16px;
    width: calc(100% - 8px);
    .f-node-output, .f-node-input {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 0.2px solid var(--node-border-color);
      background-color: var(--node-background-color);
    }
    &.top {
      top: -8px
    }
    &.bottom {
      bottom: -8px;
    }
  }
}
@mixin toolbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  position: absolute;
  right: 16px;
  top: 16px;
  button {
    font-weight: 500;
    border: none;
    border-radius: 2px;
    padding: 4px 8px;
    line-height: normal;
  }
}
