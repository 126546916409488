@import 'src/assets/css/custom/color';
@import 'src/assets/css/custom/size';
@import 'src/assets/css/custom/animations';
@import 'src/assets/css/custom/form';
@import 'src/assets/css/custom/image';
@import 'src/assets/css/custom/editorjs';
@import 'src/assets/css/custom/table';
@import 'src/assets/css/custom/chart';
@import 'src/assets/css/custom/attribute';
@import 'src/assets/css/custom/rtl';
@import 'src/assets/css/custom/gridster';
@import 'src/assets/css/custom/modal';
@import 'src/assets/css/custom/gantt';
@import 'src/assets/css/custom/tour';
@import 'src/assets/css/custom/flow';
@import '@ctrl/ngx-emoji-mart/picker';
@import 'src/assets/css/custom/emoji';
@import 'src/assets/css/custom/primeng';
@import "primeicons/primeicons.css";
@import "primeng/resources/primeng.css";

:root{
  font-family: "Poppins", Vazirmatn, 'sans-serif' !important;
  --font-family: "Poppins", Vazirmatn, 'sans-serif' !important;

}

body{
  overflow:hidden;
  background: var(--body-bg);
  color:var(--body-color);
  font-family: "Poppins", Vazirmatn, 'sans-serif' !important;
  caret-color:var(--focus-border-color);
  line-height:1.7;
  font-weight:400;
}
*{
  text-align:start;
}

.select-period {
  position: relative;

  .period-btn {
    //color: #000;
    //background-color: #fff;
    border-radius: 5px;
    border: 1px solid #cbcbcb !important;
    width: 155px;
    height: 36px;

    i {
      color: #000;
    }
  }

  .dropdown-menu {
    width: 520px;
    padding: 1rem;
  }

  &-duration {
    ul {
      width: 145px;
      list-style: none;
      padding: 5px 5px 0 20px;
      margin: 0 0 0 20px;
      border-left: 1px solid #5e627838;

      li {
        margin-bottom: 12px;

        a {
          color: #1F1F1F;
        }
      }
    }
  }

  &-content {
    display: flex;
    position: absolute;
    z-index: 103;
    top: 50px;
    right: 0;
    width: 413px;
    padding: 10px 15px;
    //background-color: #ffffff;
    background-clip: padding-box;
    opacity: 0;
    visibility: hidden;
    translate: 0 20px;
    transition: all .3s ease-in-out;
    border: 1px solid #DADADA;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.14);
    border-radius: 4px;

    &.active {
      opacity: 1;
      visibility: visible;
      translate: 0 0;
    }
  }

  li {
    margin: 0;
    position: relative;

    .nav-link.active {
      border-color: transparent;
    }

    .sub-chat {
      color: var(--orange) !important;
    }

    .active {
      background-color: transparent !important;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 3px;
        background: var(--orange);
        position: absolute;
        bottom: -1px;
        right: 0;
        left: 0;
      }
    }
  }
}

.aside .aside-menu .menu .menu-item .menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #002b412e;
  border-radius: 4px;
}

.aside .aside-menu .menu .menu-item .menu-link {
  .menu-title {
    color: #474649;
  }

  .menu-icon {
    i {
      color: #474649;
    }
  }

  &.active {
    .menu-title {
      color: #12394C;
    }

    .menu-icon {
      i {
        color: #12394C;
      }

      // Scroll
    }
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(--scroll-bg);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--scroll-color);
}

html {
  //scrollbar-width: thin;
  //scrollbar-color:#b8c4c9 #e7ebed55;
}

hr{
  background-color: var(--border-color);
}

// Loading Spinner
//.pre-loader
//  position: absolute
//  width: 100%
//  height: 100%
//  top: 0
//  right: 0
//  left: 0
//  z-index: 10
//  border-radius: 7px
//  background: rgb(242 243 245 / 72%)
//
//.loader
//  width: 82px
//  height: 18px
//  position: absolute
//  top: 50%
//  left: 50%
//  transform: translate(-50%, 50%)
//
//  &::before,
//  &::after
//    content: ''
//    position: absolute
//    left: 50%
//    transform: translate(-50%, 10%)
//    top: 0
//    background: #0000002e
//    width: 16px
//    height: 16px
//    border-radius: 50%
//    animation: jump 0.5s ease-in infinite alternate
//
//  &::after
//    background: #0000
//    color: #F6921E
//    top: 100%
//    box-shadow: 32px -20px, -32px -20px
//    animation: split 0.5s ease-out infinite alternate
//
//@keyframes split
//  0%
//    box-shadow: 8px -20px, -8px -20px
//  100%
//    box-shadow: 32px -20px, -32px -20px
//
//@keyframes jump
//  0%
//    transform: translate(-50%, -150%)
//  100%
//    transform: translate(-50%, 10%)

// Badge
.badge-card {
  padding: 8px 15.5px;
  background: #E1F3ED;
  border-radius: 14px;
  width: fit-content;
  margin: -20px 30px;

  &-title {
    margin: 0;
    font-size: 16px;
    color: #000;
  }
}

.modal-header {
  background: var(--darker);
  border-bottom: 1px solid var(--border-color);
}

.v-separator {
  width: 2px;
  background: #DEE2E6;
  height: 25px;
  display: inline-block;
  margin: auto 10px;
}

.color-gray-theme {
  color: #717377;
}

// TABLE
.table {
  border:var(--table-border);
  border-collapse: separate;
  border-spacing: 0;
  border-radius: var(--radius-large);
  margin: 0;

  td {
    padding: 0.75rem !important;
  }

  th {
    color: var(--table-header-color);
    // vertical-align: top
    align-content: center;
    border-bottom: var(--table-border);
    background-color: var(--table-header-bg);
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  //&:first-child
  //border-top-left-radius: 8px

  //&:last-child
  //border-top-right-radius: 8px
}

.clickable {
  cursor: pointer;
}

.sort {
  margin: 0;

  .arrow {
    position: relative;
    margin-right: 8px;

    i {
      font-size: 15px;
    }
  }
}

.arrow {
  display: none;
}

.sorted {
  .arrow {
    display: inline-block;
  }
}

.table-hover {
  tbody {
    tr:hover > * {
      //--bs-table-accent-bg: var(--darker) !important;
    }
  }
}

.btn-size-primary {
  padding: calc(0.78rem + 1px) calc(2.14rem + 1px) !important;
  font-size: 15px;
  border-radius: 0.3rem;
}

.iti {
  display: block !important;

  .dropdown-menu.country-dropdown {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: #c7cace;
    margin-top: -1px;
  }

  .iti__country-list {
    box-shadow: none;
    font-size: 14px;
    margin-left: 0;
    width: 244px;
    max-height: 170px;
  }

  .iti__flag-container.open + input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .search-container input {
    font-size: 14px;
    border-color: #c7cace;
    border-radius: 0;
    padding: 5px 10px;
  }

  .search-container input:focus {
    outline: none;
  }

  .iti__selected-flag {
    padding-left: 0 !important;
  }
}

ngx-intl-tel-input input {
  color: #5E6278;
  transition: color 0.2s ease, background-color 0.2s ease;
  border: 1px solid #DEE2E6 !important;
  //background-color: #fff;
  min-height: 34px;
  padding: 0.5rem 1rem;
  border-radius: 5px !important;
  display: block;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  background-clip: padding-box;
  appearance: none;

  &:focus {
    outline: none !important;
  }

  &::-webkit-input-placeholder {
    color: #bac2c7;
  }

  &:-ms-input-placeholder {
    color: #bac2c7;
  }

  &::-ms-input-placeholder {
    color: #bac2c7;
  }

  &::placeholder {
    color: #bac2c7;
  }
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}

//.iti__arrow, .ng-select .ng-arrow-wrapper .ng-arrow
//  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")
//  background-repeat: no-repeat
//  background-size: contain
//  width: 15px !important
//  height: 10px !important
//  border: none !important

.iti__flag {
  order: 2;
}


.iti .iti__country-list {
  width: 390px;
  white-space: inherit;
}

.nav-tabs .nav-link {
  //color: #474649;
}

.nav-tabs .nav-link {
  &.active {
    color: #076544;
    border-color: transparent;
    border-bottom: 1px solid #076544;

    &:hover {
      border-bottom: 1px solid #076544;
    }
  }

  &:hover {
    border-color: transparent;
  }
}

.btn-outline-primary {
  border: 1px solid var(--btn-primary-color) !important;
  color: var(--btn-primary-color);

  &:hover {
    color: #FFFFFF;
    border-color: var(--btn-primary-color) !important;
    background-color: var(--btn-primary-color) !important;
  }
}

.header-comp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: .5rem 0;
  margin-bottom: 0.2rem;

  &-section {
    padding: 0 1rem;
    border-right: 2px solid #C5C5D8;
    height: 38px;
    display: flex;
    align-items: center;

    &:last-of-type {
      border-right: unset;
    }
  }
}


.ng-select .ng-select-container {
  background-color: var(--input-bg);
  border-radius: 8px;
  border: none;
  font-weight: 400;
  color:var(--body-color);

  .ng-placeholder {
  }
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input > input{
  color:var(--body-color);
}

.mat-datepicker-toggle {
  position: absolute;
  right: 6px;
  bottom: 3px;

  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 30px;
    height: 30px;
    padding: 0;
  }

  .mdc-icon-button svg,
  .mdc-icon-button img {
    width: 19px;
    height: 19px;
  }

  .mat-mdc-icon-button .mat-mdc-button-touch-target {
    width: 37px;
    height: 14px;
  }
}

.mat-drawer{
  color:var(--body-color)!important;
}

.mat-date-range-input-wrapper {
  width: calc(50% - 4px);
}

.cdk-overlay-container {
  z-index: 1900;
}

.content {
  display: flex;
  align-items: center;
  height: 100%;
  word-break: break-word;


  &:focus-visible {
    outline: unset;
  }

  //border-radius: 4px
  //box-shadow: rgba(15, 15, 15, 0.05) 0 0 0 1px, rgba(15, 15, 15, 0.1) 0 3px 6px, rgba(15, 15, 15, 0.2) 0 9px 24px

  &:hover ~ .icon-attribute {
    display: flex;
  }
}

.compact-view {
  &:hover ~ .icon-attribute {
    display: flex;
  }
}

.form-select-rt {
  border-radius: 0;
  cursor: pointer;
  height: 100%;
  transition: none;
}

.sheet {
  flex-direction: column;
  height: 100%;

  &-left {
    height: 100%;

    .card-body {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      padding: 1.25rem .85rem !important;
    }
  }
}

.sheet-table {
  flex-grow: 1;
  position: relative;
  padding: 0 0 0 8px;
  width: 100%;
  // margin-top:3%

  &-left {
    //background-color: #ffffff;
    //border: 1px solid #EAEAEA;
    border-radius: 8px;
    //height: calc(100vh - 170px);
    height:100%;
    //overflow: auto;
  }
}

.card {
  border:1px solid var(--border-color);
  .card-header {
    position: sticky;
    top: 0;
    z-index: 9;
    background: var(--darker);
  }

  .card-body {
    padding: 2rem 1.25rem;
  }
}

.properties-item {
  min-height: 30px;
  display: flex;
  align-items: center;
  padding: 0 0.85rem;
  border-radius: 6px;
  margin-bottom: 0.55rem;
  transition: .1s ease;
  user-select: none;
  cursor: pointer;

  &.trash:hover {
    color: #eb5757;

    .icon {
      color: #eb5757;
    }
  }

  &:hover,
  &.active {
    background: var(--darker);
  }

  .icon {
    margin-right: 0.45rem;
    font-size: 14px;
    color: #000;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

a {
  outline: none;
}

.subMenu {
  position: absolute;
  top: 0;
  display: none;
  transition: 0.5s all;
  width: 100%;
  min-height: 100%;
  background: white;
  z-index: 9;
  height: 409px;
  overflow: auto;

  &.is-active {
    display: block;
  }
}

.pointer-event {
  pointer-events: all !important;
}

.pointer-event-none {
  pointer-events: none;
}

.icons-list {
  position: absolute;
  max-width: 214px;
  height: 207px;
  top: 48px;
  border-radius: 4px;
  //background: #fff;
  border: 1px solid #dddada;
  overflow-y: auto;
  box-shadow: rgba(15, 15, 15, 0.05) 0 0 0 1px, rgba(15, 15, 15, 0.1) 0 3px 6px, rgba(15, 15, 15, 0.2) 0 9px 24px;
  z-index: 111;

  .icon {
    display: inline-flex;
    width: 35px;
    height: 35px;
    font-size: 18px;
    align-items: center;
    justify-content: center;
    margin: 0.2rem;
    padding: 0.35rem;
    color: #000;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background: #efefef;
    }
  }
}

.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 110;
}

.edit {
  .content {
    display: flex;
    flex-direction: column;
    align-items: start;
    //min-height: 40px
    //background: #fff;
    border-radius: 5px;
    flex-wrap: nowrap;
    padding: 0.5rem;
    max-height: 100px;
    overflow: auto;
  }

  .characters {
    display: block;
    text-align: right;
    font-size: 12px !important;
    padding-right: 5px;
    cursor: auto;
    user-select: none;
  }

  .status-com,
  .file-com,
  .duration-com,
  .date-com,
  .select-com,
  .multi-select-com {
    min-height: 40px !important;
    background: transparent;
    border-radius: 5px;
    padding: 0.5rem;
    position: relative;
  }
}

.content,
.status-com,
.file-com,
.select-com,
.multi-select-com {
  padding: 0.3rem;
}

.bg-white-smoke {
  background-color: #F4F4F4;
}

.resizing {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 120;
  background-color: red;
}

.shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.14);
}

.dropdown-toggle::after {
  display: none !important;
}

.mat-calendar-controls {
  margin-top: 0 !important;
}

.mat-calendar-header {
  padding: 0 !important;
}

img {
  object-fit: cover;
}

icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
}

input {
  outline: none;
  border: none;
  color:var(--input-color);
  background-color:var(--input-bg);
  accent-color: var(--fade-text-color);
  border-radius:var(--radius);
  padding-left:4px;
  padding-right:4px;
}

input:focus {
  outline: none;
  border: none;
}

.intro-section {
  padding: 1rem;
  background: var(--naviblue);
  color: var(--naviblue-light-9);
  width: 100%;
  //margin-block: 2.5rem
  //border-radius: 8px
  text-align: center;
  font-size: 1.3rem;
  border-top-right-radius: var(--radius-large);
  border-top-left-radius: var(--radius-large);
}

.tooltip-inner {
  background-color: var(--tooltip-bg);
  color: var(--body-color);
  font-family: "Poppins", Vazirmatn, 'sans-serif' !important;
  border:1px solid var(--border-color);
}

.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: transparent;
}

.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: transparent;
}

.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: transparent;
}

.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: transparent;
}

.filter-section {
  display: flex;
  align-items: center;

  .sort-section {
    margin: 0 .5rem 0 0;
    text-align: center;
  }
}

.filter-content {
  display: flex;
  align-items: center;

  .add-filter,
  .delete-filter {
    display: flex;
    align-items: center;
    padding: .3rem 1rem;
    width: 115px;
    height: 34px;
    overflow: hidden;
    border-radius: 5px;
    color: rgba(55, 53, 47, 0.65);
    transition: background 20ms ease-in 0s;
    cursor: pointer;
    user-select: none;

    &:hover {
      background: rgba(55, 53, 47, 0.08);
    }
  }

  .delete-filter:hover {
    color: #ff000d;

    i {
      color: #ff000d;
    }
  }
}

.filter-query-modal {
  display: grid;
  grid-template-columns: 83px minmax(min-content, 1fr) 1fr minmax(min-content, 1fr) 32px;
  gap: 8px;
  align-items: center;
  justify-items: center;
  margin-bottom: .6rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.sort-query-modal {
  display: grid;
  grid-template-columns: minmax(min-content, 1fr) 150px 32px;
  gap: 8px;
  align-items: center;
  justify-items: center;
  margin-bottom: .6rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.border-right {
  border-right: 1px solid #c2c2c2;
}

.filter-sort-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //margin-block: 5px 8px;
  padding: 2px;
  margin-bottom:4px;

  .left,
  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;

    :is(.filter, .sort) {
      display: flex;
      align-items: center;
      column-gap: 0.3rem;
      cursor: pointer;
    }
  }
}

.filter-button {
  display: flex;
  cursor: pointer;
  border-radius: var(--radius-large);

  &-active {
    border: 1px solid var(--border-color);
  }

  &:hover {
    background-color: var(--hover-back);
  }
}

.cursor-grab {
  cursor: grab;
}

app-fill-form-public {
  display: block;
  height: 100vh;
}

.modal-large {
  max-width: 90%;
  max-height: 90%;
}

b {
  font-weight: 500 !important;
}

.poppins-font {
  font-family: Poppins, sans-serif;
}

atw-embed-viewer {
  height: 100%;
}

.separator-line {
  display: flex;
  align-items: center;
  text-align: center;
  color:var(--fade-text-color);
  margin-top:.25rem;
  margin-bottom:.25rem;
}


.separator-line::before,
.separator-line::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid var(--border-color);
  margin-left:0.5em;
  margin-right:0.5em;
}

//.separator-line:not(:empty)::before {
//  margin-right: 1em;
//  margin-left:1em;
//}
//
//.separator-line:not(:empty)::after {
//  margin-left: .75em;
//  margin-right:.75em;
//}

.display-hover {
  opacity: 0;

  &:hover {
    opacity: 1;
  }
}


.tabs {
  display: flex;
  width: 100%;
  height: 36px;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}

.tab {
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  height: 100%;
  align-content: center;
  cursor: pointer;
}

.tab.active {
  color: var(--orange);
  border-bottom: 4px solid var(--orange);
}

loadable-button {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.overflow-auto-hover {
  overflow: hidden;
  scrollbar-gutter: stable;
}

.overflow-auto-hover:hover {
  overflow: auto !important;

}

.fc-scroller{
  overflow-y:hidden !important;
}
.fc-scroller:hover{
  overflow-y:auto !important;
}

app-sidebar-item{
  height:100%;
}

html[dir=rtl] .dock-end{
  transform-origin:left !important;
}
html[dir=ltr] .dock-end{
  transform-origin:right !important;
}

.soon{
  background:var(--orange);
  color:white;
  border-radius: 16px;
  padding-left:8px;
  padding-right:8px;
}

@media print {
  .no-print{
    display:none !important;
  }
  .no-print *{
    display:none !important;
  }
}

.direction-rtl , .direction-rtl *{
  direction:rtl;
}
.direction-ltr , .direction-ltr *{
  direction:ltr;
}

.is-required::after {
  content: "*";
  color: var(--red);
}

.rotate-45{
  transform: rotate(45deg);
}
.rotate-10{
  transform: rotate(10deg);
}

select option{
  background:var(--modal-bg);
  color:var(--body-color);
  accent-color: var(--orange);
}

.ratio-16x9{
  aspect-ratio: 1.77;
}

.ratio-3x2{
  aspect-ratio: 1.5;
}

.ratio-4x1{
  aspect-ratio: 4;
}
.ratio-6x1{
  aspect-ratio: 6;
}
