:root {
  --text-color: var(--body-color) !important;
  --text-color-secondary: var(--fade-text-color) !important;
  --primary-color-text: var(--body-color) !important;
  --p-focus-ring-color: var(--orange) !important;


}

@keyframes customFadeIn {
  from {
    opacity: 0;
    transform: scale(0.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.p-dialog {
  background: transparent;
  backdrop-filter: blur(var(--backdrop-blur));
  border-radius: var(--radius-large);
  padding: 0;
  border:none;
  //transform-origin: top;
  //animation: customFadeIn 0.5s ease-out;
}

.p-dialog .p-dialog-content {
  background: var(--modal-bg) !important;
  color: var(--body-color) !important;
  padding: 0;
  //border-radius:8px;
}

.p-dialog .p-dialog-content:last-of-type {
  border-bottom-right-radius: var(--radius-large);
  border-bottom-left-radius: var(--radius-large);
}


.p-dialog .p-dialog-header {
  background: var(--modal-header-bg);
  padding: 0.5rem;
  border-top-right-radius: var(--radius-large);
  border-top-left-radius: var(--radius-large);
}

.p-dialog-right .p-dialog {
  max-height: calc(100% - 1.5rem);
}

.p-dialog-right .p-dialog .p-dialog-content:last-of-type {
  border-radius: var(--radius-large);
}

.p-dialog .p-dialog-header-icons {
  gap: 0.5rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  background: transparent;
  border-radius: var(--radius);
}

.p-dialog .p-dialog-header .p-dialog-header-icon:hover {
  background: var(--darker);
}

.p-dialog .p-resizable-handle {
  z-index: 2;
}

.p-stepper .p-stepper-panels {
  background: transparent;
  color: var(--fade-text-color);
}

.p-stepper .p-stepper-header .p-stepper-action {
  background: transparent;
  color: var(--fade-text-color);
  gap: 4px;
}

.p-stepper .p-stepper-header .p-stepper-action .p-stepper-number {
  color: var(--fade-text-color);
  border: 1px solid transparent;
  background: transparent;
  border-inline-end: 1px solid var(--fade-text-color);

}

.p-stepper .p-stepper-header.p-highlight .p-stepper-number {
  background: transparent;
  color: var(--orange);
  border: 1px solid transparent;
  border-inline-end: 1px solid var(--orange);

}

.p-stepper .p-stepper-header .p-stepper-action .p-stepper-title {
  color: var(--fade-text-color);
}

.p-stepper .p-stepper-header.p-highlight .p-stepper-title {
  color: var(--orange);
}

.p-stepper .p-stepper-header:has(~ .p-highlight) .p-stepper-separator {
  background-color: var(--orange);
}

.p-stepper .p-stepper-separator {
  background-color: var(--darker);
}

.p-togglebutton.p-button {
  background: var(--darker);
  border: 1px solid var(--border-color);
  color: var(--body-color);
}

.p-togglebutton.p-highlight .p-button-label {
  background-color: var(--darker2);
  border-color: var(--darker2);
}

.p-inputtext {
  box-shadow: none;
  background: transparent;
  padding: 0 4px;
}

.p-autocomplete {
  display: flex;
  height: 100%;
}

.p-autocomplete-panel{
  background:var(--dropdown-bg);
}

.p-rating {
  gap: 0;

  &:hover .p-rating-cancel {
    opacity: 1;
  }

}

.p-rating-cancel {
  opacity: 0;
}

.p-rating-icon:hover {
  color: var(--p-rating-icon-color);
}

atw-dialog-header, atw-page-dialog-header {
  width: 100%;
}


.p-overlaypanel {
  background-color: var(--dropdown-bg);
  color: var(--body-color);
}
.p-overlaypanel-content{
  padding:0;
}

.p-overlaypanel:before {
  border-bottom-color: var(--dropdown-bg);
}

.p-overlaypanel:after {
  border-bottom-color: var(--dropdown-bg);
}

.p-divider.p-divider-vertical:before {
  border-left: 1px solid var(--border-color);
}

.p-divider.p-divider-horizontal:before {
  border-top: 1px solid var(--border-color);
}

.p-divider-content {
  background: transparent;
  backdrop-filter: blur(2px);
}

.p-tieredmenu.p-tieredmenu-overlay {
  background: var(--dropdown-bg);
  border: 1px solid var(--border-color);
}

.p-submenu-list {
  background: var(--dropdown-bg);
  backdrop-filter: blur(10px);
  border: 1px solid var(--border-color);
}

html[dir='rtl'] .p-tieredmenu .p-menuitem-link .p-icon-wrapper {
  margin-left:0;
  margin-right:auto;
  transform: scaleX(-1);
}
html[dir='rtl'] .p-tieredmenu .p-menuitem > .p-menuitem-content .p-menuitem-link .p-menuitem-icon{
  margin-left:0.5rem;
  margin-right:0;
}
.p-menubar{
  background:transparent;
  border:none;
  padding:0;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content{
  border-radius:30px;
}

.p-menubar .p-submenu-list{
  z-index:110;
}
@media screen and (max-width: 960px) {
  .p-menubar.p-menubar-mobile-active .p-menubar-root-list{
    width:fit-content;
    background:var(--modal-bg);
    border:none;
    border-radius:var(--radius);
  }
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  background:var(--darker);
}
.p-accordion .p-accordion-content {
  background: var(--darker);

}

.p-tooltip-text{
  background:var(--modal-bg);
  color:var(--body-color);
}
.p-tooltip-arrow{
  display:none;
}
