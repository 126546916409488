.driver-popover-title , .driver-popover-description, .driver-popover-footer button, .driver-popover *{
  font-family: var(--font-family) serif !important;
}
.driver-popover-navigation-btns button{
  margin-left:4px !important;
  margin-right:4px !important;
}

.driver-popover-close-btn{
  inset-inline-end: 0!important;
  right:auto!important;
}
.driver-popover{
  background-color:var(--modal-bg)!important;
  color:var(--body-color)!important;
}
.driver-popover-arrow{
  border-color: var(--modal-bg)!important;
}
.driver-popover-arrow-side-left{
  border-right-color: transparent!important;
  border-bottom-color: transparent!important;
  border-top-color: transparent!important;
}
.driver-popover-arrow-side-right{
  border-left-color: transparent!important;
  border-bottom-color: transparent!important;
  border-top-color: transparent!important;
}
.driver-popover-arrow-side-top{
  border-left-color: transparent!important;
  border-bottom-color: transparent!important;
  border-right-color: transparent!important;
}
.driver-popover-arrow-side-bottom{
  border-left-color: transparent!important;
  border-top-color: transparent!important;
  border-right-color: transparent!important;
}
