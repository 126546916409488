gridster{
  background:transparent;
}
gridster .gridster-row {
  border-top: 0 dashed var(--darker) !important;
  border-bottom: 1px dashed var(--darker) !important;
}

gridster .gridster-column {
  border-left: 0 dashed var(--darker) !important;
  border-right: 1px dashed var(--darker) !important;
}
