@import "src/assets/css/custom/color";
@import "src/assets/css/custom/size";
:root{
--row-height: 40px;
}

//.compact{
//  max-height: var(--row-height);
//  overflow: auto;
//}
.compact-table > tbody > tr {
  max-height: var(--row-height);
}

.compact-table > tbody > tr > td, .compact-table > tbody > tr > td *:first-child {
  overflow: hidden;
  max-height: var(--row-height);
}

.compact-table > tbody > tr > td *:first-child:hover {
  //overflow-y: auto;
}

.bold {
  font-weight: 600;
}
