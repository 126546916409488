@import 'src/assets/css/custom/color';
@import 'src/assets/css/custom/size';

button {
  color: var(--body-color);
  background: var(--darker);
  border: none;
  align-items: center;
  align-content: center;
  text-align: center;
}

.btn-size-small{
  height:24px!important;
  padding:0 12px !important;
}

.btn-size-normal {

}

.btn-size-large {
  font-size: larger;
  height: 42px !important;
}

.atw-btn {
  border: none;
  border-radius: var(--radius);
  padding: 4px 12px;
  outline: none;
  transition: all 0.2s ease-in-out;
  height: var(--button-height);

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.9;
    //border: none;
    //outline: none;
  }

  &:disabled {
    opacity: 0.6;
  }
}

.btn-submit {
  @extend .atw-btn;
  background-color: var(--btn-primary-color);
  color: var(--primary-opposite-color);
}

.btn-submit-outline {
  @extend .atw-btn;
  background: transparent;
  border: 1px solid var(--btn-primary-color);
  color: var(--btn-primary-color);
}

.btn-darker {
  @extend .atw-btn;
  background-color: var(--darker);
}

.btn-darker-outline {
  @extend .atw-btn;
  background-color: transparent;
  border: 1px solid var(--darker);
}

.btn-cancel {
  @extend .atw-btn;
  background-color: transparent;
  color: var(--fade-text-color);
}

.btn-cancel-outline {
  @extend .atw-btn;
  background-color: transparent;
  color: var(--fade-text-color);
}

.btn-green {
  @extend .atw-btn;
  background-color: var(--green);
  color: var(--naviblue-light-9);
}

.btn-green-outline {
  @extend .atw-btn;
  background-color: transparent;
  border: 1px solid var(--green);
  color: var(--green);
}

.btn-red {
  @extend .atw-btn;
  background-color: var(--red);
  color: var(--naviblue-light-9);
}

.btn-red-outline {
  @extend .atw-btn;
  background-color: transparent;
  border: 1px solid var(--red);
  color: var(--red);
}

.btn-neutral, btn-neutral-outline {
  @extend .atw-btn;
  background-color: transparent;
  border: 0;
}

.btn-neutral:hover, .btn-neutral-outline:hover {
  background-color: var(--darker);
}

.btn-orange {
  @extend .atw-btn;
  background-color: var(--orange);
  color: white;
  border: 0;
}

.btn-orange-outline {
  @extend .atw-btn;
  background-color: transparent;
  color: var(--orange);
  border: 1px solid var(--orange);
}

.disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.disabled:hover {
  opacity: 0.7 !important;
}

.disabled:active {
  opacity: 0.7 !important;
}

.btn-dropdown {
  border: none;
  border-radius: 4px;
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  outline: none;
  transition: all 0.2s ease-in-out;
  height: var(--button-height);
  color: var(--primary-opposite-color);
  cursor: pointer;

  & > :first-child {
    border-inline-end: 1px solid var(--darker);
    padding-inline-start: 8px;
    padding-inline-end: 4px;
    background-color: var(--btn-primary-color);
    height: var(--button-height);
    align-content: center;
    width: fit-content;

  }

  & > :nth-child(2) {
    background-color: var(--btn-primary-color);
    padding-inline-end: 2px;
    padding-inline-start: 2px;
    height: var(--button-height);
  }

  & > :first-child:hover, & > :nth-child(2):hover {
    opacity: 0.8;
  }

  & > :first-child:active, & > :nth-child(2):active {
    opacity: 0.9;
  }
}

.placeholder {
  opacity: 0.5;
  text-align: center;
  align-content: center;
}

.text-placeholder {
  opacity: 0.5;
}

input::placeholder {
  opacity: 0.5;
}

input.input-placeholder-on-hover::placeholder {
  opacity: 0;
  padding-inline-start: 8px;
}

input.input-placeholder-on-hover:hover::placeholder {
  opacity: 0.5;
}

.show-placeholder-on-hover {
  opacity: 0;
  text-align: center;
  align-content: center;
}

.show-placeholder-on-hover:hover {
  opacity: 0.5;
}

.form-control,
.form-select, textarea {
  background-color: var(--input-bg);
  border-radius: var(--radius);
  min-height: var(--field-height);
  padding: 4px 8px;
  border: none;
  outline: none;
  color: var(--input-color);
  font-weight: 400;
}

.form-control:focus {
  background-color: var(--darker2);
  outline: none;
}

.form-control.form-control-solid {
  background-color: var(--input-bg);
  color: var(--input-color);
}

.form-control select:focus {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.form-select {
  background-color: var(--input-bg) !important;
}

.oneline-select {
  display: inline-flex;
  flex-direction: row;
  min-width: 240px;
  flex-wrap: nowrap;
  color: var(--fade-text-color);
  border-radius: var(--radius-large);
  background-color: var(--accent-color);
  border: 2px solid var(--accent-color);
}

.oneline-select > :first-child {
  border-start-start-radius: var(--radius-large);
  border-end-start-radius: var(--radius-large);
}


.oneline-select > :last-child {
  border-start-end-radius: var(--radius-large);
  border-end-end-radius: var(--radius-large);
}


.oneline-select > :not(:last-child) {
  border-inline-end: 2px solid var(--accent-color);
}

.oneline-select > span {
  padding: 2px;
  cursor: pointer;
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  align-content:center;
}

.oneline-select > span:first-child:nth-last-child(1) {
  width: 100%;
}

.oneline-select > span:first-child:nth-last-child(2),
.oneline-select > span:first-child:nth-last-child(2) ~ span,
{
  width: 50%;
}

.oneline-select > span:first-child:nth-last-child(3),
.oneline-select > span:first-child:nth-last-child(3) ~ span,
{
  width: 33.33%;
}

.oneline-select > span:first-child:nth-last-child(4),
.oneline-select > span:first-child:nth-last-child(4) ~ span,
{
  width: 25%;
}

.oneline-select > span > icon {
  margin-inline-end: 2px;
}

.oneline-select > span.active {
  background-color: var(--btn-primary-color);
  color: white;
  border-radius: var(--radius-large);
  font-weight: 400;
}

.atw-dropdown {
  height: var(--field-height);
  display: block;
}

*[ngbDropdownItem] {
  cursor: pointer;
}

.form-column {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-item-column-simple {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-item-column {
  display: flex;
  flex-direction: column;
  gap: 8px;

  & > :first-child {
    text-wrap: nowrap;
  }

  & > :nth-child(2) {
    border-radius: var(--radius-large);
    min-height: var(--field-height);
    background-color: var(--input-bg);
    border: 1px solid var(--box-title-border);
    //overflow: hidden;
  }

  & > :nth-child(2):focus-within {
    border: 1px solid var(--focus-border-color);
  }

  & > :nth-child(3) {
    font-size: 0.9rem !important;
    color: var(--fade-text-color);
    padding-inline-start: 4px;

  }
}

.border-focus:focus, .border-focus:focus-within{
  border: 1px solid var(--focus-border-color);
}

.form-item-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  & > :first-child {
    text-wrap: nowrap;
  }

  & > :nth-child(2) {
    border-radius: var(--radius-large);
    min-height: var(--field-height);
    background-color: var(--input-bg);
    border: 1px solid var(--box-title-border);
  }

  & > :nth-child(2):focus-within {
    border: 1px solid var(--focus-border-color);
  }
}

.form-oneline-item {
  display: flex;
  gap: 8px;
  flex-direction: row;
  min-height: var(--field-height);
  align-items: center;
}

.form-oneline-item > :first-child {
  align-content: center;
  flex-grow: 1;
  flex-basis: fit-content;
  flex-shrink: 0;
  min-width: 50px;
}

.form-oneline-item > :nth-child(2) {
  width: 100%;
  height: 100%;
}

.form-inline-pair {
  display: inline-flex;
  flex-direction: row;
  border-radius: var(--radius);
  align-items: center;
  height: var(--field-height);
  overflow: hidden;
  border: 1px solid var(--border-color);

  & > :first-child {
    background-color: var(--accent-color);
    padding: 8px;
    border-inline-end: 1px solid var(--border-color);
    align-content: center;
  }

  & > :nth-child(2) {
    background-color: var(--darker);
    height: var(--field-height);
    align-content: center;
  }
}

.form-input {
  //background-color: var(--naviblue-light-10;
  border-radius: var(--radius);
  min-height: var(--field-height);
}

.form-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  .label {
    min-width: 100px;
  }
}

.form-box {
  display: flex;
  margin-top: 14px;
  flex-direction: column;
  position: relative;
  border-radius: var(--radius-curve);

  & > :first-child {
    position: absolute;
    top: -16px;
    inset-inline-start: 8px;
    z-index: 1;
    background-color: var(--accent-color);
    border: 1px solid var(--box-title-border);
    color: var(--body-color);
    border-radius: 12px;
    padding-left: 8px;
    padding-right: 8px;
    text-wrap: nowrap;
    align-items: center;
    align-content: center;
    display: flex;
  }

  & > :first-child::before {
    content: '';
    position: absolute;
    top: 50%;
    inset-inline-start: -4px;
    width: 110%;
    height: 1px;
    z-index: -1;
  }

  & > :nth-child(2) {
    border-radius: var(--radius-curve);
    min-height: calc(var(--field-height) + 2px);
    background-color: var(--input-bg);
    border: 1px solid var(--box-title-border);
    overflow: hidden;
  }

  & > :nth-child(2):is(dropdown) {
    min-height: var(--field-height);
    padding: 0;
  }

  & > :nth-child(2):is(autocomplete-text) {
    padding-top: 4px;
    padding-bottom: 4px;

  }

  & > :nth-child(2):focus-within {
    border: 1px solid var(--focus-border-color);
  }
  & > :nth-child(2).invalid {
    border: 1px solid var(--red);
  }

}


.show-on-hover {
  opacity: 0;
}

.show-on-hover:hover {
  opacity: 1;
}

.hover:hover {
  background-color: var(--darker);
  border-radius: var(--radius);
  cursor: pointer;
}

.hover-circle:hover {
  background-color: var(--darker);
  border-radius: 100px;
  cursor: pointer;
}

.hover-color:hover {
  color: var(--orange);
  cursor: pointer;
}


.text-center {
  text-align: center;
  align-content: center;
}

.text-center-top {
  text-align: center;
  align-content: start;
}

.text-center-bottom {
  text-align: center;
  align-content: end;
}

.text-left-center {
  text-align: left;
  align-content: center;
}

.text-left-top {
  text-align: left;
  align-content: start;
}

.text-left-bottom {
  text-align: left;
  align-content: end;
}

.text-right-center {
  text-align: right;
  align-content: center;
}

.text-right-top {
  text-align: right;
  align-content: start;
}

.text-right-bottom {
  text-align: right;
  align-content: end;
}

.chips-format-row{
  flex-direction:row;
  flex-wrap: wrap;
}


// input validation

input.ng-touched.ng-invalid:not(.ignore-invalid-check) {
  border: 1px solid var(--red);
}


